<template>
  <el-row>
    <el-col :span="24">
      <div class="zone">
        <div class="title">热销专区</div>
          <ul>
            <li
              v-for="(item, index) in hotRecommend.categories"
              :key="index"
              :class="[index === currentIndex ? 'active' : '']"
              @mouseenter="changeIndex(index)"
            >
              {{ item.categoryName }}
            </li>
          </ul>
            <div
              class="content"
              v-for="(item, index) in 10"
              :key="index"
              v-show="index === currentIndex"
            >
              <div>
                <div
                  v-for="(item, index) in hotRecommend.products"
                  :key="index"
                  class="items"
                >
                  <Shopitem :info="item"></Shopitem>
                </div>
              </div>
            </div>
          </div>
    </el-col>
  </el-row>
</template>

<style lang="less" scoped>
@import "~style/index.less";
.zone {
  width: 1200px;
  margin: 0 auto;
  .title {
    color: #333;
    line-height: 37px;
    font-size: 26px;
    padding-bottom: 30px;
    text-align: center;
  }
  ul {
    background: #fff;
    overflow: hidden;
    li {
      float: left;
      color: @color333;
      line-height: 22px;
      padding: 17px 28px;
    }
    .active {
      background: #FFD303;
      color: #ffffff;
    }
  }
  .content {
    overflow: hidden;
    width: 100%;
    min-height: 228px;
    background: #f6f6f6;
    .items:nth-child(5n + 0) {
      margin-right: 0px;
      .item{
         margin-right: 0px;
      }
    }
  }
}
</style>

<script>
import Shopitem from "components/index/shopItem.vue";
import { hotShopInfo } from "api/home.js";
export default {
  name: "CommodityZone",
  data() {
    return {
      arr: [],
      currentIndex: 0,
      categoryId: "",
      shopInfo: [],
      hasValue: "flase",
      hotRecommendList:{},
    };
  },
   props: {
    hotRecommend: {
      type: Object,
    },
  },

  methods: {
    changeIndex(index) {
      this.currentIndex = index;
      this.categoryId = this.hotRecommend.categories[index].id;
      hotShopInfo(this.categoryId).then((data) => {
        this.hotRecommend.products = data.data.data;
      });
    },
  },
  components: {
    Shopitem,
  },
  computed: {},
  created() {
   
  },
};
</script>
